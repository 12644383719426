.page-container{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: var(--commissioner);
}

/* progress bar and right bar */
progress {
    background-color: var(--light);
    border-radius: 10px;
    border: 2px solid var(--dark-blue);
}
progress::-webkit-progress-bar {
    background-color: var(--light);
    border-radius: 10px;
    border: 2px solid var(--dark-blue);
}
progress::-webkit-progress-value {
    background-color: var(--blue);
    border-radius: 10px 0 0 10px;
} 
progress::-moz-progress-bar {
    background-color: var(--light);
    border-radius: 10px;
    border: 2px solid var(--dark-blue);
}

/* scrollbar */
::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background: #886602;
}

::-webkit-scrollbar-thumb {
    background: #21323d;
}

::-webkit-scrollbar-thumb:hover {
    background: #1b252c;
}

/* text-selection */
p::selection,
div::selection,
h1::selection,
h2::selection,
h3::selection,
a::selection{
    color: var(--black);
    background: var(--amber);
}

/* sweet alert */
.swal-text {
    font-family: var(--sans-pro);
    font-weight: bold;
  }