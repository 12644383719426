#footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 100%; */
    padding: 15px 30px;
    background: var(--dark-blue);
    color: var(--light);
}

.footer-social{
    display: flex;
}

.footer-social img{
    width: 32px;
    height: 32px;
    margin-right: 15px;
}

#copyright{
    display: flex;
    align-items: center;
}

#copyright p{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

#myLogo-footer{
    width: 50px;
    margin-right: 18px;
}

@media screen and (max-width: 400px){
    #footer{
        padding: 15px 18px;
    }
}