#about{
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-description-container{
    /* border: 1px solid red; */
    width: 50%;
    font-size: 21px;
    line-height: 1.3;
    word-spacing: 2px;
}

.first-letter::first-letter{
    font-size: 35px;
    font-weight: bold;
}

.download-cv{
    display: flex;
    align-items: center;
    color: var(--dark-blue);
    margin-right: 10px;
    text-decoration: none;
}

.download-cv img{
    margin-left: 5px;
}


@media screen and (max-width: 1024px){
    .about-description-container{
        width: 60%;
    }
}

@media screen and (max-width: 780px){
    .about-description-container{
        width: 67%;
    }
}

@media screen and (max-width: 600px){
    .about-description-container{
        width: 80%;
    }
}

@media screen and (max-width: 400px){
    .about-description-container{
        width: 85%;
    }
}